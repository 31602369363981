<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ user_details.user_firstname + " " + user_details.user_lastname }}
        </p>
        <span class="user-status">{{
          user_details.user_type_tag
            ? user_details.user_type_tag
            : user_details.user_type_name
        }}</span>
      </div>
      <b-img
        height="40"
        width="40"
        v-if="user_details.user_profile_image"
        :src="FILESURL + user_details.user_profile_image"
        rounded="circle"
      />
      <b-avatar
        size="40"
        v-else
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <!-- <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" /> -->
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MailIcon" class="mr-50" />
      <span>Inbox</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
      <span>Task</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      :to="{ name: 'chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
      <span>Chat</span>
    </b-dropdown-item>

    <!-- <b-dropdown-divider /> -->

    <b-dropdown-item
      :to="{ name: 'Settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Settings</span>
    </b-dropdown-item>

    <!--    
    <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import { TokenService } from "@/apiServices/storageService";
import { FILESURL } from "@/config";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
  },
  data() {
    return {
      avatar: "/img/13-small.d796bffd.png",
      avatarText,
      FILESURL,
    };
  },
  computed: {
    user_details() {
      return store.getters["user/getUserDetails"];
    },
  },
  methods: {
    logout() {
      TokenService.removeToken();
      this.$store.commit("user/SET_USER_DETAILS", {});
      this.$store.commit("user/SET_USER_AUTHENTICATED", false);

      this.$router.replace({ name: "login" });
    },
  },
};
</script>
